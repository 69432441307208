import { Box, CardContent, Typography } from '@mui/material'
import clsx from 'clsx'
import { ActionsWrapper } from './Actions'
import { IBaseLayout, ProductImgSize, IAdornments } from './types'
import styles from './Horizontal.module.scss'
import { Bottom } from './Bottom'
import { ProductImage } from './ProductImage'
import React from 'react'
import stylesProductPrice from '../../product-price/ProductPrice.module.scss'
import { ICartItemForPdfProps } from '../makeCartItem'
import stylesForPdf from '../../shoppingcart/pdf/ShoppingCartPDFNoOrderYet.module.scss'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { OrderItemForPdfProps } from '../makeOrderItem'

export interface IHorizontalLayoutProps extends IBaseLayout, IAdornments {
  className?: string
  productImageSize?: ProductImgSize
  mobile?: boolean
  statusInAdditionalValues?: boolean
  labelsInAdditionalValues?: boolean
  mapValues: null | ((aditionalValues: JSX.Element[]) => JSX.Element)
  bottomRightAdornments: React.ReactNode | null
  shippingValue?: { shippedAmount: number; orderedAmount: number; unit: string }
}

// TODO: This component is too overloaded and difficult to maintain, consider restructuring it.
export const Horizontal: React.FC<
  IHorizontalLayoutProps & ICartItemForPdfProps & OrderItemForPdfProps
> = (props) => {
  const { t } = useTranslation()
  const {
    className,
    productImageSize = '128',
    imageElement,
    titleElement,
    propertiesElements,
    labels,
    stockElements: statusesElement,
    counter,
    mapValues,
    topAdornments,
    leftAdornments,
    bottomAdornments,
    bottomExtension,
    bottomRightAdornments,
    actionsElements,
    statusInAdditionalValues = false,
    labelsInAdditionalValues = true,
    isCartItemForPdf,
    shippingValue,
    orderItemAmount,
    orderItemUnit,
    shoppingCartItemAmount,
    shoppingCartItemMinimumAmount,
    shoppingCartItemUnit,
    isCartItemForOrderPdf = false,
    orderPdfSupplier,
    deadProduct,
    hideActionsElements,
    hideImage,
  } = props

  const { mobile, tabletAll, tabletWide } = useBreakpoints()

  const aditionalValues: JSX.Element[] = []
  let headerSlot: React.ReactChild | React.ReactChild[] = titleElement

  let contentSlots: React.ReactChild[] = [
    <div
      key="contentSlots-horizontal-properties"
      className={clsx(styles.horizontalProperties, {
        [styles.horizontalPropertiesCenter]: deadProduct && tabletWide,
      })}
    >
      {propertiesElements}
    </div>,
  ]

  if (orderPdfSupplier) {
    contentSlots.push(
      <div key="contentSlots-orderPdfSupplier" className={styles.horizontalProperties}>
        {orderPdfSupplier}
      </div>
    )
  }

  if (topAdornments) {
    headerSlot = topAdornments
    contentSlots = [titleElement, ...contentSlots]
  }

  const labelsElementWithKey = <React.Fragment key="labelsElement">{labels}</React.Fragment>

  if (statusesElement && !isCartItemForOrderPdf && !deadProduct) {
    const statusesElementWithKey = (
      <React.Fragment key="statusesElement">{statusesElement}</React.Fragment>
    )

    if (labels) {
      // show labels w/in additional values (e.g. prices)
      if (labelsInAdditionalValues) {
        ;(!statusInAdditionalValues ? contentSlots : aditionalValues).push(statusesElementWithKey)
        aditionalValues.push(labels)
      }
      // labels displayed in pairs w/ statusElements (above additional values such as prices)
      else {
        contentSlots.push(
          <div key="contentSlots-stocksAndLabelWrapper" className={styles.stocksAndLabelWrapper}>
            {statusesElementWithKey}
            {labelsElementWithKey}
          </div>
        )
      }
    } else {
      // no labels, only statusesElements to be displayed
      ;(!statusInAdditionalValues ? contentSlots : aditionalValues).push(statusesElement)
    }
  } else {
    // no statusesElements, only labels to be displayed
    if (labels) {
      aditionalValues.push(labelsElementWithKey)
    }
  }

  let itemShippingInfo
  if (shippingValue) {
    itemShippingInfo = (
      <Box className={styles.shippingValue}>
        <Typography variant={'boldText'}>
          {`${shippingValue.shippedAmount} / ${shippingValue.orderedAmount} ${shippingValue.unit}`}
        </Typography>
        <Typography variant={'smallText'} color={'text.secondary'}>
          Geliefert
        </Typography>
      </Box>
    )
  }

  if (isCartItemForPdf) {
    const sellAmountInfoOrAsManyOrderedElement = (
      <Box className={stylesProductPrice.price}>
        <Typography
          className={clsx(stylesProductPrice.typography, stylesForPdf.fontSize12px)}
          variant={'boldText'}
        >
          {!isCartItemForOrderPdf && `${shoppingCartItemAmount} ${shoppingCartItemUnit}`}
          {isCartItemForOrderPdf && `${orderItemAmount} ${orderItemUnit}`}
        </Typography>
        <Typography
          className={clsx(stylesProductPrice.typography, stylesForPdf.fontSize10px)}
          color={'text.secondary'}
          variant={'smallText'}
        >
          {!isCartItemForOrderPdf &&
            t('SHOPPING_CART.MINIMUM_AMOUNT', {
              shoppingCartItemMinimumAmount: shoppingCartItemMinimumAmount,
              itemUnit: shoppingCartItemUnit,
            })}
          {isCartItemForOrderPdf && 'Bestellt'}
        </Typography>
      </Box>
    )

    //In order to guarantee the correct rendering order of the elements (renderPricesBlock.tsx), the following push needs to be the last element added to the array.
    aditionalValues.push(sellAmountInfoOrAsManyOrderedElement)
  }

  const notDealProductContentSlot = isCartItemForPdf ? (
    <div className={styles.wrapperContentSlots}>{contentSlots}</div>
  ) : (
    contentSlots
  )

  return (
    <CardContent
      className={clsx(styles.layoutWrapper, className, {
        [styles.positionedRelative]: deadProduct,
      })}
    >
      {leftAdornments}
      <div className={styles.layout}>
        {!hideImage && <ProductImage size={productImageSize}>{imageElement}</ProductImage>}
        <div
          className={clsx(styles.content, {
            [styles.printContent]: isCartItemForPdf,
            [styles.contentDeadProduct]: deadProduct && !mobile,
          })}
        >
          <div className={styles.headerWrap}>
            {!deadProduct && !hideActionsElements && (
              <ActionsWrapper layout="horizontal">{actionsElements}</ActionsWrapper>
            )}
            <div className={styles.header}>{headerSlot}</div>
          </div>

          {deadProduct ? (
            <div className={styles.wrapperCounterAndProductId}>
              {contentSlots}
              {counter ?? null}
              {tabletAll && bottomRightAdornments && bottomRightAdornments}
            </div>
          ) : (
            notDealProductContentSlot
          )}

          {!deadProduct && (
            <div
              className={clsx(styles.footer, {
                [styles.singleColumn]: !(counter || bottomRightAdornments),
                [styles.shippingInfo]: shippingValue,
              })}
            >
              {shippingValue && itemShippingInfo}
              {mapValues && mapValues(aditionalValues)}
              {(counter || bottomRightAdornments) && (
                <Bottom className={styles.horizontalBottomElement}>
                  {!deadProduct && counter}

                  {!deadProduct && bottomRightAdornments}
                </Bottom>
              )}
            </div>
          )}

          {bottomAdornments}
          {bottomExtension}
        </div>
      </div>
    </CardContent>
  )
}
